import { template as template_bd2731d9306446b4a3b975accd15518f } from "@ember/template-compiler";
const FKLabel = template_bd2731d9306446b4a3b975accd15518f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_10e8d7fa8c044ee68595b30eca8203c8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_10e8d7fa8c044ee68595b30eca8203c8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;

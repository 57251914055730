import { template as template_e80fd6f516694024989702f36b4bc6c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e80fd6f516694024989702f36b4bc6c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
